import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"

import styled from 'styled-components'

const Text = styled.p`
  margin-bottom: 30px;
`

const ImpressumPage = ({ location }) => (
  
  <>
  {/* Hero section  */}
  <Layout
    marginTop={50}
    noContact
    breadcrumb={
      {"Impressum": "/impressum"}
    }
  >
    <Seo 
      title="Impressum | Gartenfreunde Offenburg e.V."
      description="Gartenfreunde Offenburg e.V. ist für dich da. Im Impressum findest du die Anschrift sowie Kontaktdaten."
      lang="de"
      pathname={location.pathname}
      robots="noindex,follow"
    />
    <Section>
      <div className="row">
        <div className="col-md-8">
          <h1>Impressum</h1>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Text>
          Gemeinschaft der Gartenfreunde Offenburg e.V.<br></br>
          Im Stockfeld 3<br></br>
          77656 Offenburg<br></br>
          <br></br>
          Vereinsregister: VR 470014<br></br>
          Registergericht: Amtsgericht Freiburg<br></br>
          </Text>
          <Text>
          <b>Vertreten durch:</b><br></br>
          Robby Stüfen (1. Vorstand)<br></br>
          Sieglinde Ruthenberg (2. Vorstand)
          </Text>
          <h3 className="left">Kontakt</h3>
          <Text>
            Telefon: 0172 / 8765204<br></br>
            E-Mail: info@gartenfreunde-offenburg.de
          </Text>
          <h3 className="left">Redaktionell verantwortlich</h3>
          <Text>
            Vorstand unter der Leitung von<br></br>
            Robby Stüfen<br></br>
            Platanenallee 4<br></br>
            77656 Offenburg
          </Text>
          <h3 className="left">Haftung für Inhalte</h3>
          <Text>
            ‍Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
  ‍          <br/><br/>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt derKenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </Text>
          <h3 className="left">Haftung für Links</h3>
          <Text>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
  ‍           <br/><br/>
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einerRechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Linksumgehend entfernen.
          </Text>
          <h3 className="left">Urheberrecht</h3>
          <Text>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
  ‍            <br/><br/>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </Text>
        </div>
      </div>
    </Section>
    
  </Layout>
  </>
)
export default ImpressumPage
